<template>
  <v-dialog
    :value="value"
    max-width="500px"
    @click:outside="close"
  >
    <v-card v-if="action !== null && action !== undefined">
      <v-card-title>{{ action.title }}</v-card-title>
      <v-card-text class="mb-0 pb-0">
        <v-banner
          v-if="action.uiFields !== null && action.uiFields !== undefined && action.uiFields.length === 0"
          rounded
          single-line
          color="grey lighten-2 mb-2"
          icon="mdi-information"
        >
          {{ $t('thisActionRequiresNoSpecificConfiguration') }}
        </v-banner>
        <v-row v-if="action.uiFields.length > 0">
          <v-col
            v-for="(uiField, index) in action.uiFields"
            :key="index"
            :cols="12"
            class="pt-0"
          >
            <UiField
              v-if="uiField !== null && uiField !== undefined"
              :value="uiField"
              @change="change"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-progress-linear
        v-if="loading === true"
        indeterminate
        color="primary"
      />
      <v-card-actions>
        <v-btn
          x-large
          block
          color="success"
          :disabled="loading"
          :loading="loading"
          @click="performBulkAction"
        >
          {{ $t('performActionOnItemsLengthItems', {itemsLength: items.length}) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import UiField from "../../uiField"

  function initalDataState(){
    return {
      uiFieldsToProcess: [],
      loading: false
    }
  }

  export default {
    name: "ActionsDialog",
    components: {
      UiField
    },
    props: {
      value: {
        type: Boolean,
        default: false
      },
      items: {
        type: Array,
        default: () => []
      },
      action: {
        type: null,
        required: true
      }
    },
    data: () => {
      return initalDataState()
    },
    methods: {
      //Hotfix, needs more generic solution
      change(changedValue) {
        if(changedValue.field.key === 'itemPriceIsSubscriptionPrice' && changedValue.field.value === true) {

          const value = this.uiFieldsToProcess[0]
          value.label = this.$t('disabled')
          value.disabled = true
          value.value = ''

          this.uiFieldsToProcess[0] = value
        } else if (changedValue.field.key === 'itemPriceIsSubscriptionPrice' && (changedValue.field.value === false || changedValue.field.value === null)) {

          const value = this.uiFieldsToProcess[0]
          value.label = this.$t('recurringPrice')
          value.disabled = false

          this.uiFieldsToProcess[0] = value
        }
      },
      performBulkAction() {
        this.uiFieldsToProcess.forEach(field => {
          if(field !== null && typeof field !== 'undefined') {
            if(field.type === 'checkbox' && field.value === null) {
              field.value = false
            }
          }
        })

        let callback = 'getCustomers'
        switch (this.action.routes[0]) {
          case 'subscriptions':
            callback = 'getSubscriptions'
            break
          case 'customers':
            callback = 'getCustomers'
            break
          default:
            callback = 'getCustomers'
        }
        this.$store.commit('updateBulkOperation', {
          action: this.action,
          route: this.action.routes[0],
          uiValues: this.uiFieldsToProcess,
          payload: this.items,
          callback
        })
        this.close()
      },
      close() {
        if(this.loading === true) {
          return
        }
        Object.assign(this.$data, initalDataState())
        this.$emit('close')
      }
    },
    watch: {
      action(newValue) {
        this.uiFieldsToProcess = []
        if(newValue === null || typeof newValue === 'undefined'){
          return
        }
        newValue.uiFields.forEach(uiField => {
          if(uiField !== null && uiField !== 'undefined' && uiField.required === true) {
            this.uiFieldsToProcess.push(uiField)
          }
        })
      }
    },
    mounted() {
      this.uiFieldsToProcess = []
      if(this.action === null || typeof this.action === 'undefined'){
        return
      }
      this.action.uiFields.forEach(uiField => {
        if(uiField !== null && uiField !== 'undefined' && uiField.required === true) {
          this.uiFieldsToProcess.push(uiField)
        }
      })
    }
  }
</script>

<style scoped>

</style>
