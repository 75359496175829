<template>
  <div
    :top-load-method="refresh"
    :id="viewId"
  >
    <v-card
      class="pa-0"
      elevation="0"
      color="light"
      style="z-index: 2;"
      :style="actionBarStyle"
    >
      <v-progress-linear
        indeterminate
        v-if="processing"
      />
    </v-card>
    <v-list
      class="pa-0"
    >
      <v-toolbar
        class="fixed-bar"
        flat
        v-if="showSearch"
      >
        <v-text-field
          v-model="actionListQuery"
          class="grow mr-2"
          outlined
          hide-details
          dense
          filled
          :placeholder="$t('search')"
          clearable
        />
        <v-btn
          v-if="refreshable && loadItemsByCallback !== null && loadItemsByCallback !== undefined"
          icon
          small
          outlined
          @click="refreshItems"
          class="mr-1 ml-2 grey lighten-2"
          dark
        >
          <v-icon
            small
            color="grey darken-2"
          >
            mdi-sync
          </v-icon>
        </v-btn>
        <v-btn
          v-if="Array.isArray(actions) && actions.length > 0"
          icon
          small
          outlined
          @click="showBulkActions = !showBulkActions"
          :disabled="false"
          :loading="false"
          class="mr-1 grey lighten-2"
          dark
        >
          <v-icon
            small
            color="grey darken-2"
          >
            mdi-run-fast
          </v-icon>
        </v-btn>
      </v-toolbar>
      <div
        v-if="Array.isArray(callToActions) && callToActions.length > 0"
        class="mx-4"
      >
        <template
          v-for="(callToAction, index) in callToActions"
        >
          <v-btn
            :key="index"
            block
            @click="callToAction.callback"
            :disabled="callToAction.disabled"
						:style="computedCallToActionsButtonStyle"
            :class="(index < (callToActions.length - 1)) ? 'mb-2' : ''"
            outlined
            rounded="xl"
          >
            {{ callToAction.title }} {{ callToAction.message }}
          </v-btn>
        </template>
      </div>
      <div
        v-if="Array.isArray(callToActionsForRoute) && callToActionsForRoute.length > 0"
      >
        <template
          v-for="callToAction in callToActionsForRoute"
        >
          <v-btn
            :key="callToAction.namespace"
            block
            text
            color="primary"
            @click="selectCallToAction(callToAction)"
          >
            {{ callToAction.title }}
          </v-btn>
        </template>
      </div>
      <div
        v-if="Array.isArray(selectedFilters) && selectedFilters.length > 0"
        class="px-4"
      >
        <FilterForm
          :filtering="filtering"
          @applyFilters="filterList"
          :selected-filters="selectedFilters"
          @remove="removeFilter"
          @removeFilters="removeFilters"
        />
      </div>
      <div
        class="px-4"
        v-if="showBulkActions"
      >
        <v-list-item
          class="pl-2 pr-0"
        >
          <v-list-item-action>
            <v-checkbox
              v-show="!checkBoxesLoading"
              @change="toggleAllItems($event)"
              :disabled="checkBoxesLoading"
              :indeterminate="Array.isArray(selectedItemsArray) && Array.isArray(filteredListItems) && selectedItemsArray.length > 0 && selectedItemsArray.length !== filteredListItems.length"
            />
          </v-list-item-action>
          <v-list-item-content>
            {{ Array.isArray(selectedItemsArray) ? selectedItemsArray.length : 0 }} {{ $t('itemsSelected') }}
          </v-list-item-content>
          <v-list-item-content>
            <v-select
              hide-details
              dense
              :label="$t('action')"
              outlined
              :items="whiteListedActions"
              item-text="title"
              return-object
              v-model="selectedBulkOperation"
            />
          </v-list-item-content>
        </v-list-item>
        <v-btn
          v-if="showBulkActions"
          @click="showActionsDialog = true"
          class="mt-2"
          block
          color="primary"
          :disabled="typeof selectedBulkOperation === 'undefined' || selectedBulkOperation === null || !Array.isArray(selectedItemsArray) || selectedItemsArray.length < 1"
        >
          {{ $t('configureAction') }}
        </v-btn>
      </div>
      <div>
        <v-row dense>
          <v-col v-if="Array.isArray(filters) && filters.length > 0">
            <v-menu
              top
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  block
                  color="blue"
                  :disabled="false"
                  :loading="false"
                  style="text-transform: none !important;"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t('Filter') }}
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(filter, index) in filters"
                  :key="index"
                  @click="selectFilter(filter)"
                  link
                >
                  <v-list-item-title>{{ filter.label }}</v-list-item-title>
                  <v-list-item-icon v-if="isFilterSelected(filter)">
                    <v-icon>mdi-check</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col v-if="importDataValues !== null && importDataValues !== undefined">
            <v-btn
              block
              text
              color="blue"
              style="text-transform: none !important;"
              @click="toggleImport"
            >
              {{ $t('import') }}
            </v-btn>
          </v-col>
          <v-col v-if="allowExport">
            <v-btn
              block
              text
              color="blue"
              style="text-transform: none !important;"
              @click="exportFilteredListItems"
            >
              {{ $t('export') }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <template v-for="(item, index) in slicedItems">
        <v-list-item
          :key="index"
          class="pa-0"
        >
          <v-checkbox
            v-show="showBulkActions"
            @change="updatedSelectedItemsArray(item)"
            :value="Array.isArray(selectedItemsArray) && selectedItemsArray.some(i => i && i.uuid === item.uuid)"
            class="pl-4"
          />
          <v-list-item-content class="pa-0">
            <slot
              name="item"
              :item="item"
              :index="index"
            >
              <!-- Fallback content -->
              {{ item }}
            </slot>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <div
      v-if="Array.isArray(slicedItems) && ((Array.isArray(filteredListItems) && filteredListItems.length > slicedItems.length) || (Array.isArray(listItems) && slicedItems.length === listItems.length))"
      v-intersect.quiet="loadMoreItemsInDom"
    >
      <v-divider />
    </div>
    <div v-else-if="!Array.isArray(listItems)">
      <template v-for="index in 10">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-two-line"
          class="mx-auto"
          :key="index"
        />
        <v-divider
          v-if="index + '-div'"
          :key="index + '-div'"
          inset
        />
      </template>
    </div>
    <div
      v-if="Array.isArray(listItems) && listItems.length === 0 && emptyText !== null"
      class="text-center pa-5 text-body-2"
    >
      <div>{{ emptyText }}</div>
    </div>
    <ActionsDialog
      v-model="showActionsDialog"
      :items="selectedItemsArray"
      :action="selectedBulkOperation"
      @close="closeActionDialog"
    />
  </div>
</template>

<script>
  import FilterForm from '@/components/universalFilter/FilterForm'
  import ActionsDialog from "./ActionsDialog"

  export default {
    name: "ActionList",
    components: {ActionsDialog, FilterForm},
    props: {
      filters:  {
        type: Array,
        default: () => []
      },
      sorters:  {
        type: Array,
        default: () => []
      },
      dbTable:  {
        type: String,
        default: () => null
      },
      emptyText: {
        type: String,
        default: () => null,
      },
      emptyIcon: {
        type: String,
        default: () => null,
      },
      refreshable: {
        type: Boolean,
        default: () => false,
      },
      actions: {
        type: Array,
        default: () => []
      },
      fixedFilters: {
        type: Array,
        default: () => []
      },
      loadItemsCallback: {
        type: Function,
        default: null
      },
      preloadedItemsList: {
        type: Array,
        default: () => null
      },
      callToActions: {
        type: Array,
        default: () => []
      },
      loading: {
        type: Boolean,
        default: () => false
      },
			showSearch: {
        type: Boolean,
        default: () => true
      },
      filterListItemsCallback: {
        type: Function,
        default: () => null
      },
      sortListItemsCallback: {
        type: Function,
        default: () => null
      },
      requiresRemoteFiltering: {
        type: Boolean,
        default: () => false
      },
			importDataValues: {
				type: Object,
        default: null
			},
      preselectedFilter: {
        type: Object,
        default: () => {}
      },
	    allowExport: {
		  type: Boolean,
		    default: true
	    }
    },
    data: () => {
      return {
        listItems: null,
        db: null,
        actionListQuery: null,
        viewId: 'key_' + Math.floor(Math.random() * 100000 + 100000),
        actionBarStyle: '',
        showBulkActions: false,
        showActionsDialog: false,
        checkBoxesLoading: false,
        itemsLoading: false,
        selectedItemsArray: [],
        filteredListItems: [],
        filtering: false,
        listLength: 50,
        listLengthStep: 50,
        selectedBulkOperation: null,
        selectedFilters: [],
        listItemsInDB: null,
        processing: false,
      }
    },
    methods: {
			toggleImport() {
				this.$store.commit('updateGenericDialog', {
					title: 'Update Generic Dialog',
					component: 'components/common/GenericImport',
					props: {
						importDataValues: this.importDataValues
					}
				})
			},
      updatedSelectedItemsArray(item) {
        // Complete null value of selectedItemsArray
        if(!Array.isArray(this.selectedItemsArray)) {
          this.selectedItemsArray = [item]
          return
        }

        if(Array.isArray(this.selectedItemsArray)) {
          const index = this.selectedItemsArray.findIndex(i => i && i.uuid === item.uuid)
          if(index >= 0) {
            this.selectedItemsArray.splice(index, 1)
          } else {
            this.selectedItemsArray.push(item)
          }
        }
      },
      selectCallToAction(callToAction) {
        this.$store.commit('updateSelectedCallToAction', callToAction)
      },
      exportFilteredListItems() {
        this.$store.commit('updateExportObjects', this.filteredListItems)
      },
      loadItemsByCallback({limit, skip, conditions}) {

        if(this.loadItemsCallback === undefined || this.loadItemsCallback === null) {
          return
        }

         this.processing = true
         this.loadItemsCallback({limit, skip, conditions}).then(results => {

           if(results && !this.requiresRemoteFiltering) {
             this.applyFilters(this.fixedFilters.concat(this.selectedFilters), results).then(listItems => {
               this.listItems = listItems
             })
           }
           else {
              this.listItems = results
           }
        }).finally(() => {
          this.processing = false
        })
      },
      refreshItems() {
        if(this.loadItemsCallback !== null && this.loadItemsCallback !== undefined) {
          this.loadItemsByCallback({limit: this.listLength, skip: 0, conditions: this.queryConditions})
        }
      },
      isFilterSelected(filter) {
        return this.selectedFilters.find(selectedFilter => selectedFilter.key === filter.key)
      },
      selectFilter(filter) {

        if(this.isFilterSelected(filter)) {
          const index = this.selectedFilters.findIndex(selectedFilter => selectedFilter.key === filter.key)
          this.selectedFilter = this.selectedFilters.splice(index, 1)
          return
        }

        this.selectedFilters.push(filter)
      },
      removeFilter(index) {
        this.selectedFilters.splice(index, 1)
        this.filterList(this.selectedFilters)
      },
	    removeFilters() {
		this.selectedFilters = []
	    },
      toggleItem(e, payload) {
        if(e === true) {
          this.selectItem(payload)
        }
        if(e === false) {
          this.deselectItem(payload)
        }
      },
      toggleAllItems(e) {
        if(e === true) {
          // We need to copy the array rather than point to filteredListItems, otherwise we start mutating filteredListItems
          this.selectedItemsArray = [ ...this.filteredListItems ]
        }
        if(e === false) {
          this.selectedItemsArray = []
        }
      },
      selectItem(item) {
        if(item !== null && typeof item !== 'undefined' && item.uuid !== null && typeof item.uuid !== 'undefined') {
          const index = this.selectedItemsArray.findIndex(value => value.uuid === item.uuid)
          if (index > -1) {
            this.selectedItemsArray.splice(index, 1)
          }
          this.selectedItemsArray.push(item)
        } else {
          console.error(this.$t('couldNotFindUUIDOfItem') + ' ', item)
        }
      },
      deselectItem(item) {
        if(item !== null && typeof item !== 'undefined' && item.uuid !== null && typeof item.uuid !== 'undefined') {
          const index = this.selectedItemsArray.findIndex(value => value.uuid === item.uuid)
          if (index > -1) {
            this.selectedItemsArray.splice(index, 1)
          }
        } else {
          console.error(this.$t('couldNotFindUUIDOfItem') + ' ', item)
        }
      },
      getQueryConditionsFromFilters(filters) {

        const queryConditions = JSON.parse(JSON.stringify(filters))

        for(let i in queryConditions) {
          queryConditions[i].property = queryConditions[i].key
          delete queryConditions[i].key
          delete queryConditions[i].label
          delete queryConditions[i].type

          if(queryConditions[i].value.constructor === Array && queryConditions[i].operator !== 'between') {
            queryConditions[i].values = queryConditions[i].value
            delete queryConditions[i].items
            delete queryConditions[i].value
          }
          else if(queryConditions[i].value.constructor === Array && queryConditions[i].operator === 'between') {
            queryConditions[i].floatValues = queryConditions[i].value
            delete queryConditions[i].items
            delete queryConditions[i].value
          }
        }

        if(Array.isArray(this.fixedFilters) && this.fixedFilters.length > 0) {
          const clonedFixedFilters = JSON.parse(JSON.stringify(this.fixedFilters))
          for(let i in clonedFixedFilters) {
            const fixedFilter = clonedFixedFilters[i]
            fixedFilter.property = fixedFilter.key
            delete fixedFilter.key
						delete fixedFilter.type
            queryConditions.push(fixedFilter)
          }
        }

        return queryConditions
      },
      filterList(filters) {

        if(filters === null) {
          this.filteredListItems = this.listItems
          return
        }

        if(this.requiresRemoteFiltering) {
          this.loadItemsByCallback({limit: this.listLength, skip: 0, conditions: this.queryConditions})
          return
        }

        this.filtering = true
        this.$store.getters.applyFilters({ $worker: this.$worker, filters: filters, listOfObjects: this.listItems }).then(filteredList => {
          this.filteredListItems = filteredList
        }).finally(() => {
          this.filtering = false
        })
      },
      applyFilters(filters, listItems) {
        this.filtering = true
        return this.$store.getters.applyFilters({ $worker: this.$worker, filters: filters, listOfObjects: listItems }).then(filteredList => {
          return filteredList
        }).finally(() => {
          this.filtering = false
        })
      },
      closeActionDialog() {
        this.toggleAllItems(false)
        this.showActionsDialog = false
      },
      loadMoreItemsInDom() {
        this.listLength = this.listLength + parseInt(this.listLengthStep)
        if (this.loadItemsByCallback) {
          this.loadItemsByCallback({limit: this.listLength, skip: 0, conditions: this.queryConditions})
        }
      },
      refresh() {
        loaded('done')
      }
      /* SCROLL LISTENER REMOVED UNTIL ADMIN IS NO LONGER DISPLAYED IN AN IFRAME
      onScroll(e) {
        let windowTop = window.top.scrollY
        if(windowTop < 200) {
          this.actionBarStyle = ''
        } else if (windowTop > 300) {
          this.actionBarStyle = 'position: fixed; width: ' + document.getElementById(this.viewId).getBoundingClientRect().width + 'px;'
        }
      }
      */
    },
    computed: {
			computedCallToActionsButtonStyle() {
				const primaryColor = this.primaryColor
				return {
					backgroundColor: `${primaryColor}20`,
					borderColor: primaryColor,
					color: primaryColor,
				}
			},
			primaryColor() {
				return this.$store.getters.primaryColor
			},
      callToActionsForRoute() {

				if(this.$store.state.callToActions === null || this.$store.state.callToActions === undefined) {
					return []
				}

        return this.$store.state.callToActions.filter(object => object.routes.includes(this.$route.path))
      },
      queryConditions() {
        return this.getQueryConditionsFromFilters(this.selectedFilters)
      },
      organizationUUID() {
        return this.$store.state.organization.uuid
      },
      showFilters: {
        get() {
          return this.$store.state.showFilters
        },
        set(value) {
          this.$store.commit('updateShowFilters', value)
        }
      },
      slicedItems(){
        if(Array.isArray(this.filteredListItems) && this.filteredListItems.length > 0) {
          return this.filteredListItems.slice(0, this.listLength)
        }
        return []
      },
      userIsAdmin () {
        return !!(this.$store.state.user.email.includes('@salescloud.is'))
      },
      whiteListedActions() {
        if(this.userIsAdmin) {
          return this.actions
        }
        return this.actions.filter(action => action.whiteListed === true)
      },
      actionSuccess() {
        return this.$store.state.actionSuccess
      },
      actionError() {
        return this.$store.state.actionError
      }
    },
    watch: {
      preloadedItemsList(newValue) {
        if(Array.isArray(newValue)) {
          this.listItems = newValue
        }
      },
      listItems(newValue) {
        this.filteredListItems = newValue

        if(this.dbTable && this.$db && this.$db[this.dbTable]) {
          this.$db[this.dbTable].bulkPut(this.filteredListItems)
        }
      },
      actionListQuery(query) {
        if(query !== '' && query !== null) {

           this.$worker.run((workerQuery, listItems) => {
            
             if(Array.isArray(listItems)) {
               return listItems.filter(listItem => {

                 if(listItem) {
                   const keys = Object.keys(listItem)

                   for(let i in keys) {
                     const key = keys[i]
                     const value = listItem[key] + ''
                     if(listItem[key] !== null && listItem[key] !== undefined && typeof listItem[key] === 'string' && value.toLowerCase().includes(workerQuery)) {
                       return true
                     }
                   }

                   //Subscriptions
                   if(listItem.payer && listItem.payer.address !== null && listItem.payer.address !== undefined && listItem.payer.address.name_line !== null && listItem.payer.address.name_line !== undefined) {
                     if(listItem.payer.address.name_line.toLowerCase().includes(workerQuery)) {
                       return true
                     }
                   }

                   if(listItem.receiver && listItem.receiver.address !== null && listItem.receiver.address !== undefined && listItem.receiver.address.name_line !== null && listItem.receiver.address.name_line !== undefined) {
                     if(listItem.receiver.address.name_line.toLowerCase().includes(workerQuery)) {
                       return true
                     }
                   }

                   //Customers
                   if(listItem.address !== null && listItem.address !== undefined && listItem.address.name_line !== null && listItem.address.name_line !== undefined) {
                     if(listItem.address.name_line.toLowerCase().includes(workerQuery)) {
                       return true
                     }
                   }
                   //Order ofl
                   if(listItem.customer !== null && listItem.customer !== undefined && listItem.customer.address !== null && listItem.customer.address !== undefined  && listItem.customer.address.name_line !== null && listItem.customer.address.name_line !== undefined) {
                     if(listItem.customer.address.name_line.toLowerCase().includes(workerQuery)) {
                       return true
                     }
                   }

                   return false
                 }
                 return false
               })
             }
             return listItems
           }, [query.toLowerCase(), this.listItems]).then(results => {
            this.filteredListItems = results
          })
         }
         else {
           this.filteredListItems = this.listItems
         }
      },
      actionSuccess(newValue) {
        if(newValue) {
          this.loadItemsByCallback({limit: this.listLength, skip: 0, conditions: this.queryConditions})
        }
      },
      actionError(newValue) {
        if(newValue) {
          this.loadItemsByCallback({limit: this.listLength, skip: 0, conditions: this.queryConditions})
        }
      }
    },
    mounted() {
      /* SCROLL LISTENER REMOVED UNTIL ADMIN IS NO LONGER DISPLAYED IN AN IFRAME
      window.addEventListener("scroll", this.onScroll)
      this.onScroll()
       */
      this.processing = this.loading

      if(this.preloadedItemsList !== null) {
        this.listItems = this.preloadedItemsList
      }

      // if initialSelectedFilter exists and is in filters, add to selected filters. then apply filters
      if(typeof this.preselectedFilter !== 'undefined' &&  this.preselectedFilter !== null) {
        this.selectFilter(this.preselectedFilter)
      }

      if(this.loadItemsCallback !== null && this.loadItemsCallback !== undefined) {
        this.loadItemsByCallback({limit: this.listLength, skip: 0, conditions: this.queryConditions})

        if(typeof this.preselectedFilter !== 'undefined' &&  this.preselectedFilter !== null) {
          this.filterList(this.selectedFilters)
        }
      }

      this.processing = false
    },
    beforeDestroy() {
      /* SCROLL LISTENER REMOVED UNTIL ADMIN IS NO LONGER DISPLAYED IN AN IFRAME
      window.removeEventListener("scroll", this.onScroll)
      */
  }
}
</script>

<style>
.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: inherit;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  z-index: 3;
}
</style>
