<template>
  <v-form>
    <template v-for="(selectedFilter, index) in selectedFilters">
      <FilterFormItem
        :key="index"
        v-model="selectedFilters[index]"
        @remove="removeFilter(index)"
        :filtering="filtering"
      />
    </template>
    <v-btn
      color="primary"
      block
      type="submit"
      @click="applyFilters"
      :disabled="filtering"
      :loading="filtering"
    >
      {{ $t('apply') }}
    </v-btn>
    <v-btn
      color="primary"
      text
      block
      class="text-none mt-1"
      @click="removeAllFilters"
    >
      {{ $t('Clear filters') }}
    </v-btn>
  </v-form>
</template>

<script>

  import FilterFormItem from './FilterFormItem'

  export default {
    name: 'FilterForm',
    props: {
      selectedFilters: {
        type: Array,
        default: () => []
      },
      filtering: {
        type: Boolean
      }
    },
    components: {
      FilterFormItem
    },
    methods: {
      applyFilters(e) {
        e.preventDefault()
        this.$emit('applyFilters', this.selectedFilters)
      },
      removeFilter(index) {
        this.$emit('remove', index)
      },
	    removeAllFilters() {
		  this.$emit('removeFilters')
	    }
    }
  }
</script>
