<template>
  <div>
    <v-row
      class="mb-0 pb-0"
      v-if="value.type === 'text'"
    >
      <v-col cols="6">
        <v-select
          :disabled="filtering"
          outlined
          dense
          hide-details
          :label="$t('operator')"
          :items="textOperators"
          item-value="key"
          item-text="label"
          v-model="value.operator"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          :disabled="filtering"
          append-icon="mdi-close"
          @click:append="removeFormItem"
          dense
          hide-details
          outlined
          :label="value.label"
          v-model="value.value"
        />
      </v-col>
    </v-row>
    <v-row
      class="mb-0 pb-0"
      v-if="value.type === 'number'"
    >
      <v-col cols="6">
        <v-select
          :disabled="filtering"
          outlined
          dense
          hide-details
          :label="$t('operator')"
          :items="numberOperators"
          item-value="key"
          item-text="label"
          v-model="value.operator"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          :disabled="filtering"
          append-icon="mdi-close"
          @click:append="removeFormItem"
          dense
          hide-details
          outlined
          :label="value.label"
          v-model="value.value"
        />
      </v-col>
    </v-row>
    <v-row v-if="value.type === 'boolean'">
      <v-col cols="6">
        <v-select
          :disabled="filtering"
          outlined
          dense
          hide-details
          :label="$t('operator')"
          :items="textOperators"
          item-value="key"
          item-text="label"
          v-model="value.operator"
        />
      </v-col>
      <v-col cols="6">
        <v-checkbox
          style="margin-top: 0;"
          :disabled="filtering"
          append-icon="mdi-close"
          @click:append="removeFormItem"
          hide-details
          :label="value.label"
          v-model="value.value"
        />
      </v-col>
    </v-row>
    <v-row v-if="value.type === 'select'">
      <v-col cols="6">
        <v-select
          :disabled="filtering"
          outlined
          dense
          hide-details
          :label="$t('operator')"
          :items="textOperators"
          item-value="key"
          item-text="label"
          v-model="value.operator"
        />
      </v-col>
      <v-col cols="6">
        <v-select
          :disabled="filtering"
          append-icon="mdi-close"
          @click:append="removeFormItem"
          dense
          hide-details
          outlined
          :label="value.label"
          multiple
          small-chips
          deletable-chips
          :items="value.items"
          v-model="value.value"
        />
      </v-col>
    </v-row>
    <v-row v-if="value.type === 'autocomplete'">
      <v-col cols="6">
        <v-select
          :disabled="filtering"
          outlined
          dense
          hide-details
          :label="$t('operator')"
          :items="textOperators"
          item-value="key"
          item-text="label"
          v-model="value.operator"
        />
      </v-col>
      <v-col cols="6">
        <v-autocomplete
          :disabled="filtering"
          append-icon="mdi-close"
          @click:append="removeFormItem"
          dense
          hide-details
          outlined
          :label="value.label"
          multiple
          small-chips
          deletable-chips
          :items="value.items"
          v-model="value.value"
        />
      </v-col>
    </v-row>
    <v-row
      class="mb-0 pb-0"
      v-if="value.type === 'date'"
    >
      <v-col cols="6">
        <v-select
          :disabled="filtering"
          outlined
          dense
          hide-details
          :label="$t('operator')"
          :items="dateOperators"
          item-value="key"
          item-text="label"
          v-model="value.operator"
        />
      </v-col>
      <v-col cols="6">
        <date-selection
          dense
          hide-details
          :label="value.label"
          v-model="value.value"
          :disabled="filtering"
          outlined
          no-icon
          :full-width="false"
        />
      </v-col>
    </v-row>
    <v-row
      class="mb-0 pb-0"
      v-if="value.type === 'datetime'"
      align="center"
    >
      <v-col cols="5">
        <date-selection
          dense
          hide-details
          :label="value.label + ' ' + $t('from').toLowerCase()"
          v-model="value.value[0]"
          :disabled="filtering"
          outlined
          no-icon
          :full-width="false"
        />
      </v-col>
      <v-col cols="5">
        <date-selection
          dense
          hide-details
          :label="value.label + ' ' + $t('to').toLowerCase()"
          v-model="value.value[1]"
          :disabled="filtering"
          outlined
          no-icon
          :full-width="false"
        />
      </v-col>
      <v-col
        cols="2"
        class="text-end"
      >
        <v-btn
          icon
          @click="removeFormItem"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DateSelection from '../../components/common/DateSelection.vue'

export default {
    name: 'FilterFormItem',
    components: {
      DateSelection
    },
    props: {
      value: {
        type: Object,
        default: () => {}
      },
      filtering: {
        type: Boolean
      }
    },
    computed: {
      dateOperators() {
            return [
                {
                    key: 'equals',
                    label: this.$t('equals')
                },
                {
                    key: 'before',
										label: this.$t('before')
                },
                {
                    key: 'after',
										label: this.$t('after')
                },
            ]
        },
       numberOperators() {
            return [
                {
                    key: 'equals',
										label: this.$t('equals')
                },
                {
                    key: 'lessThan',
                    label: this.$t('lessThan')
                },
                {
                    key: 'greaterThan',
										label: this.$t('greaterThan')
                },

            ]
        },
        textOperators() {
            return [
                {
                    key: 'contains',
										label: this.$t('contains')
                },
                {
                    key: 'equals',
										label: this.$t('equals')
                }
            ]
        }
    },
    methods: {
        removeFormItem() {
            this.$emit('remove')
        }
    }
}
</script>
